import React from 'react'

import MainContent from '../components/MainContent'
import Footer from '../components/Footer'

import '../layouts/index.css'
import styles from '../css-modules/country.module.css'

import countryContent from '../data/country-content.json'

const Country = ({ location }) => (
  <div className={styles.country}>
    <div className={styles.social}>
      <a href="/">
        <img src="/static/Revision-Insurance_Logo.png" />
      </a>
    </div>
    <MainContent
      padding={0}
      content={countryContent}
      country={location.state ? location.state.country : 'United Kingdom'}
    />
    <Footer />
  </div>
)

export default Country
