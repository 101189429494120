import React from 'react'

import styles from '../css-modules/main-content.module.css'

import SectionTitle from './SectionTitle'
import SectionImage from './SectionImage'

function onlyRevision(content, country, item, index) {
  var result = (<div className={styles.gridIconFrame}>
                  <img
                    className={item.disabled ? styles.disabledIcon : styles.gridIcon}
                    src={`${content.imageString ||
                      content[country].imageString}${index + 1}.png`}
                  />
                  <h4 className={item.disabled ? styles.disabled : null}>
                    {item.header}
                  </h4>
                  <p>{item.text}</p>
                  {item.link ? (
                    <a className={styles.button} target="_blank" href={item.link}>
                      More Info
                    </a>
                  ) : null}
                </div>) 

  return index === 0? result : (<div className={styles.gridIconFrame}> </div>)
}

export default ({ content, country, padding }) => {
  return (
    <div
      className={styles.mainContent}
      style={{ padding: `${padding}px 0 60px` }}
    >
      <SectionTitle
        content={content.sectionTitle || content[country].sectionTitle}
      />
      {country ? (
        <SectionImage sectionImage={content[country].sectionImage} />
      ) : (
        <div>
          <br />
          <br />
          <br />
        </div>
      )}
      <div className={styles.gridWrapper}>
        {(content.items || content[country].items).map((item, index) => (
          <div className={styles.gridIconFrame}>
            { onlyRevision(content, country, item, index) }
          </div>
        ))}
      </div>
    </div>
  )
}
//<img
//className={item.disabled ? styles.disabledIcon : styles.gridIcon}
//src={`${content.imageString ||
//  content[country].imageString}${index + 1}.png`}
///>
//<h4 className={item.disabled ? styles.disabled : null}>
//{item.header}
//</h4>
//<p>{item.text}</p>
//{item.link ? (
//<a className={styles.button} target="_blank" href={item.link}>
//  More Info
//</a>
//) : null}